// Translated
// Migrated
<template>
  <div
    class="floating-action-button"
    :class="{ '--expanded': expanded, [`--${position}`]: true }"
  >
    <div
      class="items-center pt-4 px-4 pb-2 relative"
      :class="[{ 'flex': expanded }, `bg-${color}`]"
    >
      <template v-if="expanded">
        <button
          class="btn btn-link text-white mr-4"
          type="button"
          @click="toggle"
        >
          <fa
            icon="chevron-up"
            width="16"
          />
        </button>
        <slot
          name="header"
          v-bind="slotData"
        />
      </template>
      <div
        v-else
        class="text-center"
      >
        <h4>
          <fa
            :icon="icon"
            width="24"
          />
        </h4>
        <hr />
        <button
          class="btn btn-link text-white"
          type="button"
          @click="expanded = !expanded"
        >
          <fa
            icon="chevron-down"
            width="16"
          />
        </button>
      </div>
    </div>
    <div
      v-if="expanded"
      class="fab-body"
    >
      <slot
        name="body"
        v-bind="slotData"
      />
    </div>
  </div>
</template>

<script>
import { faInfoCircle } from '@fortawesome/pro-regular-svg-icons'

export default defineNuxtComponent({
  props: {
    icon: {
      type: [Object, String],
      default: faInfoCircle,
    },

    color: {
      type: String,
      default: 'blue',
    },

    position: {
      type: String,
      default: 'right',
    },
  },

  data () {
    return {
      expanded: false,
    }
  },

  computed: {
    slotData () {
      return {
        onToggle: this.toggle,
        expanded: this.expanded,
      }
    },
  },

  methods: {
    toggle () {
      this.expanded = !this.expanded
    },
  },
})
</script>

<style lang="scss" scoped>
/*! purgecss start ignore */
.floating-action-button{
  position: fixed;
  top: 3rem;
  z-index: 10000;
  box-shadow: 0 0 2px rgb(202, 202, 202);

  color: white;

  @media (max-width: theme('screens.md')) {
    top: 3rem;
  }

  &.--expanded {
    top: 7rem;
  }
  &.--right {
    right: 1rem;
  }
  &.--left {
    left: 1rem;
  }

  .fab {
    &-body {
      padding: 1rem;
      background: white;
      color: theme('colors.black');
    }
  }
}
/*! purgecss end ignore */
</style>
